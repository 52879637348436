import _ from "lodash";
import * as APIS from "../../libs/apis";
import * as OAuth2 from "./../../libs/oAuth2";
import { DEBUG, ERROR } from "../../libs/utils";
import { LOGIN, MEMBER } from "../../redux/action_const";
import { getCookie, removeCookie, setCookie } from "../../libs/cookie";
import { BACKEND, PAPI } from "./../../libs/_endpoint";
import axios from "axios";
import default_logo from "../../assets/img/default_avatar.png";
import { fbAuth, logout } from "../../libs/Firebase";
import { signInWithPhoneNumber, signInWithCustomToken } from "firebase/auth";
import parsePhoneNumber from 'libphonenumber-js'


const cooldown_time = 60;
const options_5y = { expires: 5 * 365 };
const options_1d = { expires: 1 };
const CookieNameOTP = "__otp";

export const getCookieSignin = (callback) => {
  return (dispatch) => {
    let cookieOtp = getCookie(CookieNameOTP);
    if (!cookieOtp || !cookieOtp.timestamp) {
      removeCookie(CookieNameOTP);
      return callback && callback();
    }
    try {
      DEBUG(CookieNameOTP, cookieOtp);
      cooldown(dispatch, cookieOtp.timestamp);
      return dispatch({
        type: LOGIN.PHONE_REQUEST_OTP,
        isProcess: false,
        data: cookieOtp,
      });
    } catch (error) {
      removeCookie(CookieNameOTP);
      ERROR(error);
      return callback && callback();
    }
  };
};

export const signinWithPhoneNumber = (
  countryCode,
  phoneNumber,
  shortName,
  gResponse = ""
) => {
  let errorCode = { code: "oauth2/argument-phone-error" };
  let phoneNumberIFormat = changePhoneNumberFormat(countryCode, phoneNumber);

  return (dispatch) => {
    dispatch({ type: LOGIN.IS_PROCESS, isProcess: true });
    setTimeout(() => {
      if (!phoneNumberIFormat)
        return dispatch({
          type: LOGIN.VERIFY_OTP,
          isProcess: false,
          isError: true,
          error: errorCode,
        });

      let cookieOtp = getCookie(CookieNameOTP);
      if (cookieOtp) {
        if (phoneNumber === cookieOtp.phoneNumber) {
          if (
            parseInt(cookieOtp.timestamp / 1000) + cooldown_time >
            parseInt(window.ps.getNowDate().getTime() / 1000)
          ) {
            DEBUG("Cookie not expiry");
            cooldown(dispatch, cookieOtp.timestamp);
            return dispatch({
              type: LOGIN.PHONE_REQUEST_OTP,
              isProcess: false,
              data: cookieOtp,
            });
          }
        }
      }

      if (countryCode === "+66") {
        OAuth2.signinWithPhoneNumber(phoneNumberIFormat, gResponse)
          .then(({ data }) => {
            removeCookie(CookieNameOTP);
            if (!data.result) {
              ERROR("Error", data.error);
              return dispatch({
                type: LOGIN.PHONE_REQUEST_OTP,
                isProcess: false,
                isError: true,
                error: data.error,
              });
            }

            let _time = window.ps.getNowDate().getTime();
            let __Otp = Object.assign(
              data.data,
              { countryCode, phoneNumber },
              { timestamp: _time }
            );
            setCookie(CookieNameOTP, __Otp);
            cooldown(dispatch, _time);
            return dispatch({
              type: LOGIN.PHONE_REQUEST_OTP,
              isProcess: false,
              data: __Otp,
            });
          })
          .catch((err) => {
            ERROR("CATCH signinWithPhoneNumber", err);
            return dispatch({
              type: LOGIN.PHONE_REQUEST_OTP,
              isProcess: false,
              isError: true,
              error: {
                code: err.response.data?.error || "recaptcha_verify_invalid",
              },
            });
          });
      } else {
        const appVerifier = window.recaptchaVerifier;
        let foreignFormat = changeForeignFormat(countryCode, phoneNumber);
        let phone;

        try {
          phone = parsePhoneNumber(foreignFormat, shortName);
          phone = phone.number;
        } catch (error) {
          let err = { code: "parsePhoneNumber_too_short" };
          return dispatch({
            type: LOGIN.PHONE_REQUEST_OTP,
            isProcess: false,
            isError: true,
            error: err,
          });
        }

        signInWithPhoneNumber(fbAuth, phone, appVerifier)
          .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            removeCookie(CookieNameOTP);

            let _time = window.ps.getNowDate().getTime();
            let __Otp = Object.assign(
              {},
              { countryCode, phoneNumber, shortName },
              { timestamp: _time }
            );
            setCookie(CookieNameOTP, __Otp);
            cooldown(dispatch, _time);
            return dispatch({
              type: LOGIN.PHONE_REQUEST_OTP,
              isProcess: false,
              data: __Otp,
            });
          })
          .catch((error) => {
            return dispatch({
              type: LOGIN.PHONE_REQUEST_OTP,
              isProcess: false,
              isError: true,
              error: error,
            });
          });
      }
    }, 200);
  };
};

export const resendOTP = (
  countryCode,
  phoneNumber,
  shortName,
  gRecaptcha,
  callback
) => {
  let errorCode = { code: "oauth2/argument-phone-error" };
  let phoneNumberIFormat = changePhoneNumberFormat(countryCode, phoneNumber);
  return (dispatch) => {
    dispatch({ type: LOGIN.IS_RESEND_OTP, isProcess: true });
    if (!phoneNumberIFormat)
      return dispatch({
        type: LOGIN.VERIFY_OTP,
        isProcess: false,
        isError: true,
        error: errorCode,
      });

    if (countryCode === "+66") {
      OAuth2.signinWithPhoneNumber(phoneNumberIFormat, gRecaptcha)
        .then(({ data }) => {
          removeCookie(CookieNameOTP);
          if (typeof callback === "function")
            callback(data.error && new Error(data.error), data);

          if (!data.result) {
            ERROR("Error", data.error);
            return dispatch({
              type: LOGIN.PHONE_REQUEST_OTP,
              isProcess: false,
              isError: true,
              error: data.error,
            });
          }

          let _time = window.ps.getNowDate().getTime();
          let __Otp = Object.assign(
            data.data,
            { countryCode, phoneNumber },
            { timestamp: _time }
          );
          setCookie(CookieNameOTP, __Otp);
          cooldown(dispatch, _time);
          return dispatch({
            type: LOGIN.PHONE_REQUEST_OTP,
            isProcess: false,
            data: __Otp,
          });
        })
        .catch((err) => {
          let errorCode = err;
          const errData = _.get(err, "response.data.error", false);
          if (errData) {
            if (errData.indexOf("recaptcha-invalid") > -1) {
              errorCode = { code: "recaptcha_verify_invalid" };
            } else {
              errorCode = { code: errData };
            }
          } else {
            errorCode = err;
          }
          dispatchOperationError(dispatch, errorCode);
        });
    } else {
      const appVerifier = window.recaptchaVerifier;
      let foreignFormat = changeForeignFormat(countryCode, phoneNumber);
      let phone;

      try {
        phone = parsePhoneNumber(foreignFormat, shortName);
        phone = phone.number;
      } catch (error) {
        let err = { code: "parsePhoneNumber_too_short" };
        return dispatch({
          type: LOGIN.PHONE_REQUEST_OTP,
          isProcess: false,
          isError: true,
          error: err,
        });
      }

      signInWithPhoneNumber(fbAuth, phone, appVerifier)
        .then((confirmationResult) => {
          window.confirmationResult = confirmationResult;
          removeCookie(CookieNameOTP);
          let _time = window.ps.getNowDate().getTime();
          let __Otp = Object.assign(
            {},
            { countryCode, phoneNumber, shortName },
            { timestamp: _time }
          );
          setCookie(CookieNameOTP, __Otp);
          cooldown(dispatch, _time);
          return dispatch({
            type: LOGIN.PHONE_REQUEST_OTP,
            isProcess: false,
            data: __Otp,
          });
        })
        .catch((error) => {
          return dispatch({
            type: LOGIN.PHONE_REQUEST_OTP,
            isProcess: false,
            isError: true,
            error: error,
          });
        });
    }
  };
};

export const cancelOTP = () => {
  return (dispatch) => {
    let cookieOtp = getCookie(CookieNameOTP);
    if (cookieOtp && cookieOtp.timestamp) {
      if (
        parseInt(cookieOtp.timestamp / 1000) + cooldown_time <
        parseInt(window.ps.getNowDate().getTime() / 1000)
      ) {
        DEBUG("Remove Cookie", CookieNameOTP);
        removeCookie(CookieNameOTP);
      }
    }
    return dispatch({ type: LOGIN.CANCEL_OTP });
  };
};

function changePhoneNumberFormat(countryCode, phoneNumber) {
  return String(phoneNumber).length === 10
    ? String(phoneNumber).replace(/^0/g, countryCode)
    : String(phoneNumber).length === 9 && countryCode === "+66"
    ? String(countryCode).concat(phoneNumber)
    : phoneNumber;
}

function changeForeignFormat(countryCode, phoneNumber) {
  return String(phoneNumber).replace(/^0/g, countryCode);
}

function dispatchOperationError(dispatch, err) {
  let errorCode = { code: _.get(err, "code", "oauth2/argument-phone-error") };
  dispatch({
    type: LOGIN.PHONE_REQUEST_OTP,
    isProcess: false,
    isError: true,
    error: errorCode,
  });
}

function cooldown(dispatch, _lastLogin) {
  let cooldown = cooldown_time;
  let _time = parseInt(window.ps.getNowDate().getTime() / 1000);
  _lastLogin = parseInt(_lastLogin / 1000);

  cooldown = _lastLogin + cooldown > _time ? _lastLogin + cooldown - _time : 0;

  window.ps.process_cooldown && clearInterval(window.ps.process_cooldown);

  dispatch({ type: LOGIN.SET_RESEND_OTP_COOLDOWN, cooldown: cooldown });
  window.ps.process_cooldown = setInterval(() => {
    cooldown = cooldown - 1;
    dispatch({ type: LOGIN.SET_RESEND_OTP_COOLDOWN, cooldown: cooldown });
    if (cooldown < 0) clearInterval(window.ps.process_cooldown);
  }, 1000);
}

export const verifyOTP = (
  countryCallingCode,
  phoneNumber,
  refCode,
  otp,
  line,
  countryCode,
  callback
) => {
  let errorCode = { code: "oauth2/invalid-otp" };
  let phoneNumberIFormat = changePhoneNumberFormat(
    countryCallingCode,
    phoneNumber
  );
  otp = otp.trim();

  return async (dispatch) => {
    dispatch({ type: LOGIN.IS_PROCESS, isProcess: true });

    if (!otp)
      return dispatch({
        type: LOGIN.VERIFY_OTP,
        isProcess: false,
        isError: true,
        error: errorCode,
      });

    try {
      let verified = false;
      let verified_firebase;

      if (countryCallingCode === "+66") {
        verified = await OAuth2.verifyOTP({
          phoneNumberIFormat,
          refCode,
          otp,
          countryCode,
        });
      } else {
        verified_firebase = await window.confirmationResult.confirm(otp);
        const token = verified_firebase.user.accessToken;

        verified = await OAuth2.verifyOTP({
          phoneNumberIFormat,
          countryCode,
          token,
        });
      }

      callback &&
        callback(
          verified.data.error && new Error(verified.data.error),
          verified.data
        );

      if (!verified.data.result) {
        return dispatch({
          type: LOGIN.VERIFY_OTP,
          isProcess: false,
          isError: true,
          error: verified.data.error,
        });
      }
      removeCookie(CookieNameOTP);

      try {
        await signInWithCustomToken(fbAuth, verified.data.data.customRPPointFirebaseToken);
        setCookie("__a", verified.data.data.__a, options_1d);
        setCookie("__s", verified.data.data.__s, options_5y);
        setCookie("__cid", verified.data.data.__cid, options_5y);
      
     
      } catch (err) {
        removeCookie("__a");
        removeCookie("__s");
        removeCookie("__cid");
        await logout();
      
        return dispatch({
          type: LOGIN.VERIFY_OTP,
          isProcess: false,
          isError: true,
          error: errorCode
        });
      }

      let profile = await APIS.getUserProfile(verified);
      let memberBadgeCode = profile.data.memberBadgeCode;
      let phoneNumber = profile.data.phoneNumber;
      let this_profile = profile.data.data;

      let profile_member = {
        isLoading: false,
        auth: true,
        register: this_profile[0].is_registered,
      };

      if (this_profile[0].is_registered && line) {
        APIS.updateProfileLineId({
          businessCode: line.businessCode,
          lineId: line.lineUserInfo.userId,
          lineDisplayName: line.lineUserInfo.displayName,
        }).then((updateRes) => {
          this_profile[0].line_list = updateRes.data.data;
        });
      }

      setNavigatorCredential({
        id: memberBadgeCode,
        name: profile.data.phoneNumber,
        photo_uri: this_profile[0].photo_uri,
      });

      Object.assign(window.ps, { uid: memberBadgeCode });
      return dispatch({
        type: MEMBER.SET_USER_PERMISSION,
        data: profile_member,
        profile: this_profile,
        memberBadgeCode: memberBadgeCode,
        phoneNumber: phoneNumber,
      });
    } catch (err) {
      if (err && err.code && err.name && err.name === "FirebaseError") {
        errorCode.code = err.code;
      }

      return dispatch({
        type: LOGIN.VERIFY_OTP,
        isProcess: false,
        isError: true,
        error: errorCode,
      });
    }
  };
};

export const signinWithLine = (
  lineUserInfo,
  lineUserToken,
  businessCode,
  callback
) => {
  return async (dispatch) => {
    try {
      let signinResult = await OAuth2.signinWithLine(
        lineUserToken,
        businessCode
      );
      dispatch({ type: LOGIN.SIGN_IN_FROM_LINE });
      if (!signinResult.data.data) {
        callback(false);
        return dispatch({
          type: LOGIN.SET_LINE_USER_INFO,
          lineUserInfo: lineUserInfo,
          businessCode: businessCode,
        });
      } else {
        
        try {
          await signInWithCustomToken(fbAuth, signinResult.data.data.customRPPointFirebaseToken);
          setCookie("__a", signinResult.data.data.__a, options_1d);
          setCookie("__s", signinResult.data.data.__s, options_5y);
          setCookie("__cid", signinResult.data.data.__cid, options_5y);
        } catch (err) {
          removeCookie("__a");
          removeCookie("__s");
          removeCookie("__cid");
          await logout();
        }

       
        let profile = await APIS.getUserProfile(signinResult);
        let memberBadgeCode = profile.data.memberBadgeCode;
        let phoneNumber = profile.data.phoneNumber;

        let this_profile = profile.data.data;
        let profile_member = {
          isLoading: false,
          auth: true,
          register: this_profile[0].is_registered,
        };

        setNavigatorCredential({
          id: memberBadgeCode,
          name: profile.data.phoneNumber,
          photo_uri: this_profile[0].photo_uri,
        });
        callback(true);
        Object.assign(window.ps, { uid: memberBadgeCode });
        return dispatch({
          type: MEMBER.SET_USER_PERMISSION,
          data: profile_member,
          profile: this_profile,
          memberBadgeCode: memberBadgeCode,
          phoneNumber: phoneNumber,
        });
      }
    } catch (err) {}
  };
};

export const checkCredentialAutoSignin = (callback) => {
  return async (dispatch) => {
    let credential = await getNavigationCredential();
    if (credential) {
      if (typeof callback === "function") callback(null, credential);
    } else {
      if (typeof callback === "function") callback(null, null);
    }
  };
};
export const autoSignInWithCredential = (credential, callback) => {
  return async (dispatch) => {
    if (credential) {
      if (credential.type === "password") {
        let ck = JSON.parse(atob(credential.password));
        if (ck.s && ck.c) {
          setCookie("__s", ck.s, options_5y);
          setCookie("__cid", ck.c, options_5y);
          window.location.reload();
        }
      }
      if (typeof callback === "function") callback(null, credential);
    } else {
      if (typeof callback === "function") callback(null, null);
    }
  };
};

export const getAuthState = (callback) => {
  return async (dispatch) => {
    try {
      if (!getCookie("__s") || !getCookie("__cid"))
        return dispatch({
          type: MEMBER.SET_USER_PERMISSION,
          data: { isLoading: false },
        });

      let auth_res = await OAuth2.getAuthState();
      let auth_data = auth_res.data;

      // Auth invalid
      if (!auth_data.result) {
        if (auth_data.error.code === "oauth2/invalid-token")
          removeCookie("__cid");

        if (typeof callback === "function")
          callback(new Error(auth_data.error.code));

        dispatch({
          type: MEMBER.SET_USER_PERMISSION,
          data: { isLoading: false },
        });
        throw auth_data.error.code;
      }

      try {
        await signInWithCustomToken(fbAuth, auth_data.data.customRPPointFirebaseToken);
        setCookie("__a", auth_data.data.__a, options_1d);
      } catch (err) {
        removeCookie("__a");
        await logout();
      }
      
      let profile_res = await APIS.getUserProfile();
      let profile_data = profile_res.data;
      if (profile_data.error && profile_data.error.code === 'phone-number-used') {
        Object.assign(window.ps, { uid: null })
        removeCookie("__cid");
        removeCookie("__a");
        removeCookie("__s")
        await logout();
        window.location.href = '/login'
      }

      const this_profile = profile_data.data;
      if (typeof callback === "function")
        callback(null, {
          profile: this_profile,
          memberBadgeCode: profile_data.memberBadgeCode,
          phoneNumber: profile_data.phoneNumber,
        });

      Object.assign(window.ps, { uid: profile_data.memberBadgeCode });
      let profile_member = {
        isLoading: false,
        auth: true,
        register: this_profile[0].is_registered,
      };
      setNavigatorCredential({
        id: profile_data.memberBadgeCode,
        name: profile_data.phoneNumber,
        photo_uri: this_profile[0].photo_uri,
      });
      return dispatch({
        type: MEMBER.SET_USER_PERMISSION,
        data: profile_member,
        profile: this_profile,
        memberBadgeCode: profile_data.memberBadgeCode,
        phoneNumber: profile_data.phoneNumber,
      });
    } catch (error) {
      if (typeof callback === "function") callback(error);
      return dispatch({
        type: MEMBER.SET_USER_PERMISSION,
        data: { isError: true },
      });
    }
  };
};

export const signInWithReference = (code, callback) => {
  return async (dispatch) => {
    try {
      let auth_res = await OAuth2.signInWithReference(code);
      let auth_data = auth_res.data;

      if (!auth_data.result) {
        if (typeof callback === "function") callback("result error");
        return dispatch({
          type: LOGIN.VERIFY_OTP,
          isProcess: false,
          isError: true,
          error: auth_data.error,
        });
      }

      try {
        await signInWithCustomToken(fbAuth, auth_data.data.customRPPointFirebaseToken);
        setCookie("__a", auth_data.data.__a, options_1d);
        setCookie("__s", auth_data.data.__s, options_5y);
        setCookie("__cid", auth_data.data.__cid, options_5y);
      } catch (err) {
        removeCookie("__a");
        removeCookie("__s");
        removeCookie("__cid");
        await logout();
      }

      let profile_res = await APIS.getUserProfile();
      let profile_data = profile_res.data;
      let this_profile = profile_data.data;

      let profile_member = {
        isLoading: false,
        auth: true,
        register: this_profile[0].is_registered,
      };
      if (typeof callback === "function") callback(null, profile_data);

      Object.assign(window.ps, { uid: profile_data.memberBadgeCode });
      return dispatch({
        type: MEMBER.SET_USER_PERMISSION,
        data: profile_member,
        profile: this_profile,
        memberBadgeCode: profile_data.memberBadgeCode,
        phoneNumber: profile_data.phoneNumber,
      });
    } catch (error) {
      ERROR(error);
      if (typeof callback === "function") callback(error);
      return dispatch({
        type: MEMBER.SET_USER_PERMISSION,
        data: { isError: true },
      });
    }
  };
};

export const refreshToken = (callback) => {
  return async (dispatch) => {
    try {
      let auth_res = await OAuth2.refreshToken();
      let auth_data = auth_res.data;

      if (!auth_data.result) {
        let authstate_res = await OAuth2.getAuthState();
        let authstate_data = authstate_res.data;

        if (!authstate_data.result) {
          if (authstate_data.error.code === "oauth2/invalid-token")
            removeCookie("__cid");

          if (typeof callback === "function")
            callback(new Error(authstate_data.error.code));
        } else {
          setCookie("__a", authstate_data.data.__a, options_1d);
          if (typeof callback === "function") callback(null, true);
        }
      } else if (auth_data.result && auth_data.data.__a) {
        setCookie("__a", auth_data.data.__a, options_1d);
        if (typeof callback === "function") callback(null, true);
      } else {
        if (typeof callback === "function") callback(null, true);
      }
    } catch (error) {
      if (typeof callback === "function") callback(new Error(error));
      return dispatch({
        type: MEMBER.SET_USER_PERMISSION,
        data: { isError: true },
      });
    }
  };
};

export const syncTime = (callback) => {
  return (dispatch) => {
    let start_request = new Date().getTime();

    axios({
      method: "get",
      url: `${BACKEND}/hello/time`,
    })
      .then((data) => {
        window.res_data = data;
        let transport_time = new Date().getTime() - start_request;
        let synctime =
          new Date().getTime() - new Date(data.data).getTime() - transport_time;
        DEBUG(
          "synctime_ms",
          "transport_time",
          transport_time,
          "sync",
          synctime
        );
        if (typeof callback === "function") callback(null, synctime);
        return dispatch({
          type: LOGIN.SYNCTIME,
          data: { synctime },
        });
      })
      .catch((error) => {
        if (typeof callback === "function") callback(new Error(error));
      });
  };
};

export const submitVerifyReCaptcha = (phoneNumber, token, callback) => {
  return () => {
    axios({
      method: "post",
      url: `${PAPI}/recaptcha/verify`,
      data: {
        phoneNumber: phoneNumber,
        gRecaptcha: token,
      },
    })
      .then((response) => {
        const data = response.data.data;
        if (data && data.success) {
          if (typeof callback === "function") callback(null, data);
          return data;
        } else {
          throw new Error(`reCAPTCHA is invalid.`);
        }
      })
      .catch((error) => {
        ERROR("submitVerifyReCaptcha ", error.message || error);
        if (typeof callback === "function") callback(error.message);
      });
  };
};

/**
 *
 * @param {object} options id, name, photo_uri
 */
export function setNavigatorCredential(options) {
  try {
    if (!window.PasswordCredential) return null;
    let credential = new window.PasswordCredential({
      id: options.id,
      name: options.name,
      password: btoa(
        JSON.stringify({ s: getCookie("__s"), c: getCookie("__cid") })
      ),
      provider: `${window.location.protocol}//${window.location.hostname}`,
      iconURL:
        options.photo_uri ||
        `${window.location.protocol}//${window.location.host}${default_logo}`,
    });
    window.navigator.credentials.store(credential).catch(console.error);
  } catch (error) {}
}

async function getNavigationCredential() {
  try {
    let options = {
      password: true,
      unmediated: false,
    };
    let credential = await window.navigator.credentials.get(options);
    return credential;
  } catch (error) {}
}

export const setTodo = (todo) => {
  return (dispatch) => {
    dispatch({
      type: LOGIN.SET_TODO_SEND_OTP_VIA_EMAIL,
      data: todo,
    });
  };
};

export const verifyEmailWithPhoneNumber = (email, phoneNumber, callback) => {
  const email_from_user = email.trim();
  return (dispatch) => {
    axios({
      method: "post",
      url: `${PAPI}/emailLogin/getEmailByPhoneNumber`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
      data: { phoneNumber },
    })
      .then(({ data }) => {
        if (typeof callback === "function") callback(null, data);

        let valid_email = String(data.data.email);
        let email_verified = data.data.email_verified;
        let member_badge_code = data.data.member_badge_code;

        if (email_from_user.toLowerCase() === valid_email.toLowerCase() && email_verified) {
          dispatch({
            type: LOGIN.SET_IS_EMAIL_VERIFIED,
            data: true,
            memberBadgeCode: member_badge_code,
          });
        } else {
          return dispatch({ type: LOGIN.SET_IS_EMAIL_VERIFIED, data: false });
        }
      })
      .catch((error) => {
        ERROR("verifyEmailWithPhoneNumber", error.message || error);
        if (typeof callback === "function") callback(error.message);
        return dispatch({ type: LOGIN.SET_IS_EMAIL_VERIFIED, data: false });
      });
  };
};

export const resetIsEmailVerified = () => {
  return (dispatch) => {
    dispatch({ type: LOGIN.SET_IS_EMAIL_VERIFIED, data: null });
  };
};

export const signinWithEmail = ({ member_badge_code }, callback) => {
  return async (dispatch) => {
    try {
      let signinResult = await OAuth2.signinWithEmail(member_badge_code);
      try {
        await signInWithCustomToken(fbAuth, signinResult.data.data.customRPPointFirebaseToken);
        setCookie("__a", signinResult.data.data.__a, options_1d);
        setCookie("__s", signinResult.data.data.__s, options_5y);
        setCookie("__cid", signinResult.data.data.__cid, options_5y);
      } catch (err) {
        removeCookie("__a");
        removeCookie("__s");
        removeCookie("__cid");
        await logout();
      }
     

      let profile = await APIS.getUserProfile(signinResult);
      let memberBadgeCode = profile.data.memberBadgeCode;
      let phoneNumber = profile.data.phoneNumber;

      let this_profile = profile.data.data;
      let profile_member = {
        isLoading: false,
        auth: true,
        register: this_profile[0].is_registered,
      };

      setNavigatorCredential({
        id: memberBadgeCode,
        name: profile.data.phoneNumber,
        photo_uri: this_profile[0].photo_uri,
      });

      Object.assign(window.ps, { uid: memberBadgeCode });
      removeCookie(CookieNameOTP);
      if (typeof callback === "function") callback(null, profile.data);
      return dispatch({
        type: MEMBER.SET_USER_PERMISSION,
        data: profile_member,
        profile: this_profile,
        memberBadgeCode: memberBadgeCode,
        phoneNumber: phoneNumber,
      });
    } catch (error) {
      ERROR(error);
      if (typeof callback === "function") callback(error);
      return dispatch({
        type: MEMBER.SET_USER_PERMISSION,
        data: { isError: true },
      });
    }
  };
};

export const verifyEmailWithHash = (email, callback) => {
  return (dispatch) => {
    axios({
      method: "post",
      url: `${PAPI}/emailLogin/verifyEmailWithHash`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
      data: { email },
    })
      .then(({ data }) => {
        if (typeof callback === "function") callback(null, data);

        let result = data.data;
        if (result.length === 1) {
          dispatch({
            type: LOGIN.SET_IS_EMAIL_VERIFIED,
            data: true,
            memberBadgeCode: result[0].member_badge_code,
            selectAccount: false,
          });
        } else if (result.length > 1) {
          dispatch({
            type: LOGIN.SET_IS_EMAIL_VERIFIED,
            data: true,
            selectAccount: true,
            accountList: result,
          });
        } else {
          dispatch({
            type: LOGIN.SET_IS_EMAIL_VERIFIED,
            data: false,
            selectAccount: false,
          });
        }
      })
      .catch((error) => {
        ERROR("verifyEmailWithHash", error.message || error);
        if (typeof callback === "function") callback(error.message);
        dispatch({
          type: LOGIN.SET_IS_EMAIL_VERIFIED,
          data: false,
          selectAccount: false,
        });
      });
  };
};

export const getProfileForLogin = (memberBadgeCode, callback) => {
  return (dispatch) => {
    axios({
      method: "post",
      url: `${PAPI}/emailLogin/getProfileForLogin`,
      headers: {
        Authorization: `Bearer ${getCookie("__a")}`,
        Client: getCookie("__cid"),
      },
      data: { memberBadgeCode },
    })
      .then(({ data }) => {
        let dataObject = data.data;
        Object.assign(dataObject, { memberBadgeCode: memberBadgeCode });

        if (typeof callback === "function") callback(null, dataObject);
        dispatch({ type: LOGIN.SET_PROFILE_FOR_LOGIN, data: dataObject });
      })
      .catch((error) => {
        ERROR("getProfilebyMemberBadgeCode", error.message || error);
        if (typeof callback === "function") callback(error.message);
        return dispatch({ type: LOGIN.SET_PROFILE_FOR_LOGIN, data: [] });
      });
  };
};

export const setCooldown = () => {
  let _time = window.ps.getNowDate().getTime();
  return (dispatch) => {
    cooldown(dispatch, _time);
  };
}