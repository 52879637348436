import { initializeApp } from "firebase/app"
import { getAuth, onAuthStateChanged } from 'firebase/auth'
import { getFirestore } from "firebase/firestore"
import { getStorage, ref } from 'firebase/storage'
import { DEBUG } from "./utils"

const fbConfig = require('./../config').GlobalConfig.firebase
let firebaseApp

fbInit()
function fbInit() {
  if (!firebaseApp) {
    firebaseApp = initializeApp(fbConfig)
  }
}

export const fb = firebaseApp;
export const fbAuth = getAuth(firebaseApp)
export const fbFirestore = getFirestore(firebaseApp)
export const fbStorage = getStorage(firebaseApp)
export const imageRef = ref(fbStorage, 'images')
export const memberProfileRef = (memberBadgeCode) => ref(fbStorage, `${memberBadgeCode}/profile`);
export const memberBusinessPointRequestRef = (memberBadgeCode, business, fileName) => ref(fbStorage, `${memberBadgeCode}/businesses/${business}/pointRequest/${fileName}`);
export function listenToAuthStateChanges(callback) {
  onAuthStateChanged(fbAuth, (user) => {
    callback(user)
  })
}

export async function getIdToken() {
  const user = fbAuth.currentUser;
  if (!user) {
    throw new Error("No authenticated user");
  }

  const idToken = await user.getIdToken(true);
  return idToken;
}

export async function logout() {
  try {
    await fbAuth.signOut();
    DEBUG("User logged out successfully.");
  } catch (error) {
    DEBUG("Logout failed:", error);
  }
}
